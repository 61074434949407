<template>
  <div class="order-list">
    <div
      class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh; z-index: 10; position: absolute; left: 50%">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tableSendMessage :clients="clients" @messageCreateModal="messageCreateModal" @editClient="editClient"></tableSendMessage>
    </div>
    <ModalSendMessage :clientPhone="clientPhone" />
    <modal-create-client @refresh="refresh"></modal-create-client>
    <modal-edit-client @refresh="refresh" :id="id"></modal-edit-client>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent" ></filter-modalVue>
  </div>
</template>
<script>
import ModalSendMessage from "@/views/component/Modal/ModalMessage/ModalSendMessage.vue";
import modalCreateClient from "@/views/component/Modal/ModalClients/modalCreateClient.vue";
import modalEditClient from "@/views/component/Modal/ModalClients/modalEditClient.vue";
import tableSendMessage from '@/views/component/Table/tableSendMessage.vue'
export default {
  components: {
    ModalSendMessage,
    tableSendMessage,
    modalCreateClient,
    modalEditClient
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID',},
        // { key: "id", label: "ID "},
        { key: "division_name", label: "Подразделение"},
        { key: "first_name", label: "ФИО"},
        { key: "gender", label: "Пол"},
        { key: "phone", label: "Телефон"},
        { key: "client_status_info", label: "Статус входа в приложении"},
        { key: "dop_info", label: "Дополнительная информация"},
        // {
        //   key: "balance",
        //   label: "Баланс",
        //   sortable: true,
        //   thStyle: { width: "30px" },
        // },
        // {
        //   key: "birth_date",
        //   label: "Дата рождения",
        //   sortable: true,
        //   thStyle: { width: "30px" },
        // },
        // {
        //   key: "count_orders",
        //   label: "Количество заказов ",
        //   sortable: true,
        //   thStyle: { width: "50px" },
        // },
        // {
        //   key: "last_auth_date_mob_app",
        //   label: "Последняя дата входа",
        //   sortable: true,
        //   thStyle: { width: "70px" },
        // },
        // {
        //   key: "email",
        //   label: "Email",
        //   sortable: true,
        //   thStyle: { width: "30px" },
        // },
        // { key: 'is_active', label: 'Лицевые счета', sortable: true, thStyle: {width: '30px'} },
      ],
      clients: [],
      showPreloader: false,
      clientPhone: null,
      id: null
    };
  },
  methods: {
    sendToParent(tableData) {
      this.clients = tableData;
      this.$store.commit("pageData/setdataCount", this.clients.length);
    },
    messageCreateModal(item) {
      this.clientPhone = item.phone
    },
    editClient(item) {
      this.id = item.id
      this.$bvModal.show("EditClientModal")
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.clients = [];
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.clients = res.data;
          this.$store.commit("pageData/setdataCount", this.clients.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    refresh() {
        this.clients = [];
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`).then((res) => {
          this.clients = res.data;
          this.$store.commit("pageData/setdataCount", this.clients.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
    }
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      if (val) {
        this.clients = [];
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.clients = res.data;
          this.$store.commit("pageData/setdataCount", this.clients.length);
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
